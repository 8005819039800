import { Grid, GridRow, GridItem } from './../Grid';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { Wrapper, LongTextContainer } from './styles';
import { ILongTextComponentProps } from './types';
import { NodeRendererType } from '@graphcms/rich-text-types';
import { Text, Title } from './../Typography';
import { Picture } from 'react-responsive-picture';
import { getPictureSources } from '../../modules/getPictureSources';
import { Widget } from './Widget';

// custom renderer to fix semantics & styling
export const CorpCustomRenderers: NodeRendererType = {
  embed: {
    Widget: (props) => {
      return <Widget {...props} />;
    },
  },
  h1: (comp: any) => {
    return (
      <Title tag="h2" type="md" className="test-md">
        {comp?.children?.props?.content?.[0]?.text || ''}
      </Title>
    );
  },
  h2: (comp: any) => {
    return (
      <Title tag="h3" type="sm" className="test-sm">
        {comp?.children?.props?.content?.[0]?.text || ''}
      </Title>
    );
  },
  h3: (comp: any) => {
    return (
      <Title tag="h2" type="md" className="test-md">
        {comp?.children?.props?.content?.[0]?.text || ''}
      </Title>
    );
  },
  h4: (comp: any) => {
    return (
      <Title tag="h3" type="sm" className="test-sm">
        {comp?.children?.props?.content?.[0]?.text || ''}
      </Title>
    );
  },
  h5: (comp: any) => {
    return (
      <Title tag="h4" type="xs" className="test-xs">
        {comp?.children?.props?.content?.[0]?.text || ''}
      </Title>
    );
  },
  h6: (comp: any) => {
    return (
      <Title tag="h5" type="xxs" className="test-xxs">
        {comp?.children?.props?.content?.[0]?.text || ''}
      </Title>
    );
  },
  blockquote: (comp: any) => {
    return <q>{comp?.children}</q>;
  },
  Asset: {
    image: (comp: any) => {
      return (
        <>
          <Picture sources={getPictureSources(comp)} alt={comp?.altText} />
          {comp?.caption && (
            <Text tag="p" type="s" className="test-xxs">
              {comp?.caption}
            </Text>
          )}
        </>
      );
    },
  },
};

export const CorpRichText = ({
  content,
  theme,
  references,
}: {
  content: any;
  theme?: string;
  references?: any;
}) => {
  return (
    <LongTextContainer data-test="long-text" className={`theme-${theme}`}>
      <RichText content={content} renderers={CorpCustomRenderers} references={references} />
    </LongTextContainer>
  );
};

export function LongText({ longText, theme }: ILongTextComponentProps) {
  return (
    <Wrapper>
      <Grid>
        <GridRow columns={16}>
          <GridItem colStart={4} colSpan={10}>
            <CorpRichText
              content={longText?.raw.children || { children: [] }}
              references={longText?.references}
              theme={theme}
            />
          </GridItem>
        </GridRow>
      </Grid>
    </Wrapper>
  );
}
