import escapeHtml from 'escape-html';

interface WidgetProps {
  type: string;
  url?: string;
  imageSource?: string;
  imageAlt?: string;
}

export const Widget = ({ type, url, imageSource, imageAlt }: WidgetProps) => {
  switch (true) {
    case type === 'kununu':
      if (!url || !imageSource) {
        return null;
      }

      return (
        <a href={escapeHtml(url)} target="_blank" rel="noopener noreferrer">
          <img alt={imageAlt} src={escapeHtml(imageSource)} />
        </a>
      );
    default:
      return null;
  }
};
